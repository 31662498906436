import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import { GetInContact } from "../components/getInContact";
import { HeroText } from '../components/HeroText';

import imageTrainingFull from '../images/training-full.jpg';
import imagePartnersFhJoanneum from '../images/fh-joanneum.png';
import imagePartnersFhSalzburg from '../images/fh-salzburg.png';
import imagePartnersFhStpoelten from '../images/fh-stpoelten.png';

const TrainingPage = () => (
  <Layout activeMenuItem={'training'}>
    <Seo
      title="Usability"
      keywords={[`design`, `strategisches design`]}
      description={'Lernen Sie Personas zu entwickeln, Usability-Tests durchzuführen und Ihr Produkt selbst zu verbessern.'}
    />

    <HeroText>Lernen Sie Personas zu entwickeln, Usability-Tests durchzuführen und Ihr Produkt selbst zu
      verbessern.</HeroText>

    <div className="content-wrapper">
      <div className="content pure-g">
        <div className="l-box pure-u-1 pure-u-md-1-2">
          <h3>Usability-Workshops für Teams</h3>
          <p>Workshop-Teilnehmer erfahren mehr über unseren <Link to="/design">Design-Prozess</Link> bei Simplease und
            lernen, wie sie selbstständig Usability-Tests planen, organisieren und durchführen. Workshops finden in der
            Regel direkt am Arbeitsplatz (oder in einem Meeting-Raum) statt, können aber auch in unserem Büro in Graz
            abgehalten werden und dauern 1 bis 2 Tage.</p>
        </div>
        <div className="l-box pure-u-1 pure-u-md-1-2">
          <h3>Individuelles Training</h3>
          <p>Speziell für Startups entwickeln wir ein Trainingsprogramm, mit dem Mitarbeiter in den Bereichen Usability
            und Design ausgebildet werden. Wir arbeiten am besten mit Personen, die hochmotiviert sind und wirklich
            etwas Neues lernen wollen, um sich selbst weiter zu entwickeln. <a
              href="mailto:office@simplease.at?subject=Individuelles%20Training">Schreiben Sie eine kurze E-Mail</a> und
            wir erzählen Ihnen mehr.</p>
        </div>
      </div>
    </div>

    <div className="content-wrapper image-full">
      <img src={imageTrainingFull} alt="CreativeMornings-Vortrag" />
    </div>

    <div className="content-wrapper">
      <div className="content pure-g">
        <div className="l-box pure-u-1 pure-u-md-1-2">
          <h3>Wir unterrichten an der FH</h3>
          <p>Wir haben User-Interface-Design, Usability und Web-Entwicklung an 3 Fachhochschulen in Österreich
            unterrichtet.</p>
        </div>
        <div className="l-box pure-u-1-3 pure-u-md-1-6">
          <img src={imagePartnersFhJoanneum} alt="Logo FH Joanneum" />
        </div>
        <div className="l-box pure-u-1-3 pure-u-md-1-6">
          <img src={imagePartnersFhSalzburg} alt="Logo FH Salzburg" />
        </div>
        <div className="l-box pure-u-1-3 pure-u-md-1-6">
          <img src={imagePartnersFhStpoelten} alt="Logo FH St. Pölten" />
        </div>
      </div>
      <div className="content pure-g" id="students">
        <div className="l-box pure-u-1">
          <h4>Auszüge der Studenten-Evaluierungen:</h4>
        </div>
        <div className="l-box pure-u-1 pure-u-md-1-2">
          <blockquote>„Praxisnah, sympathisch und voller Elan. Bitte mehr davon“</blockquote>
        </div>
        <div className="l-box pure-u-1 pure-u-md-1-2">
          <blockquote>„User-zentriertes Design war noch nie so spannend!“</blockquote>
        </div>
        <div className="l-box pure-u-1 pure-u-md-1-2">
          <blockquote>„[…] das waren die interessantesten&nbsp;Usability-Vorlesungen,
            die&nbsp;wir&nbsp;an&nbsp;der&nbsp;FH je hatten.“
          </blockquote>
        </div>
        <div className="l-box pure-u-1 pure-u-md-1-2">
          <blockquote>„Eine der allerbesten LVs“</blockquote>
        </div>
      </div>
    </div>

    <GetInContact />
  </Layout>
);

export default TrainingPage
